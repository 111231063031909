<template lang="html">
  <div class="pos container-100">
    <div v-if="!smallDrawer" class="player container-100" :class="{'hide':noShow}"
      @touchstart.passive="closedrawer"
    >
      <div class="songinfo">

        <img :src="imgsrc" class="playimage" alt="">
        <div class="imageoverlay">

        </div>
        <h2 class="title">{{title}}</h2>
        <p class="theme" style="font-size: 16px">{{theme}}</p>
      </div>
      <div class="controls container-100">
        <div class="playandvolume">

            <RangeSlider @change="seekTo" :progress="{currentTime: progress.currentTime, duration: progress.duration, percentage: progressPercent}" />

          <p v-if="!loading && !errorMsg" class="time">{{currentTime}} / {{duration}}</p>
          <div v-else-if="!errorMsg" class="loadcontainer">
            <p>Vi er ved at finde podcasten frem til dig...</p>
            <div class="loader"></div>
          </div>
          <div class="error" v-else>
            <p>{{errorMsg}}</p>
          </div>
          <div id="controlbuttons" v-if="!loading && !errorMsg">

            <button id="close" @click="$emit('seekback')">
              <i class="material-icons-round">replay_30</i>
            </button>
            <button v-if="isPlaying" id="play" @click="$emit('stop')">
              <i class="material-icons-round">pause</i>
            </button>
            <button v-else id="play" @click="$emit('play')">
              <i class="material-icons-round">play_arrow</i>
            </button>
            <button id="close" @click="$emit('seekforward')">
              <i class="material-icons-round">forward_30</i>
            </button>


          </div>


        </div>

      </div>
      <div id="navicon" @click="hide(); activeChoice()">
        <div class="navbot" @click="$emit('close')">
            <p class="expandtext" style="margin-left: 5px">Luk</p>
            <i class="material-icons-round">close</i>
        </div>

        <div class="navbot">
          <p class="expandtext">Vis mindre</p>
          <i class="material-icons-round">expand_less</i>
        </div>
      </div>

    </div>
    <div v-else id="smallDrawer" class="container-100" @touchstart.passive="closedrawer">
      <div class="progress-bar absolutify">
        <div class="progress-fill" :style="'width: '+progressPercent+'%'">

        </div>
      </div>

      <div class="controls container-100 smallcontrols">

        <button v-if="isPlaying" id="play" @click="$emit('stop')">
          <i class="material-icons-round">pause</i>
        </button>
        <button v-else id="play" @click="$emit('play')">
          <i class="material-icons-round">play_arrow</i>
        </button>

      </div>
      <div id="navicon" @click="show(); activeChoice()">
        <p class="expandtext">Vis mere</p>
        <i class="material-icons-round">expand_more</i>
      </div>
    </div>
  </div>

</template>

<script>

import RangeSlider from '@/components/elements/RangeSlider.vue';

export default {
  data: function(){
    return{
      value: 100,
      noShow: false,
      smallDrawer: false,
      clientY: 0,
      height: 400,
      hasMadeActiveChoice: false,
      seekTimeout: null,
    }
  },
  props:{
    imgsrc: {type: String, default: 'https://dummyimage.com/512x512'},
    title: {type: String, default: 'Song_Title_Here'},
    theme: {type: String, default: 'Ukendt Tema'},
    isPlaying: {type: Boolean, default: false},
    access_level: {type: Number, default: 0},
    progress: {type: Object, default: function(){return {currentTime: 0, duration: 1, percentage: 0}} },
    errorMsg: {type: String, default: ''}
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  watch: {
    title: function(newval, oldval){
      if(newval != oldval){
        this.hasMadeActiveChoice = false,
        this.show();
      }
    }
  },
  computed:{
    progressPercent(){
      if(!this.progress || this.progress.currentTime == 0 || this.progress.duration == 0)
        return 0;
      return Math.floor((this.progress.currentTime / this.progress.duration)*100);
    },
    currentTime(){
      return this.format(this.progress.currentTime);
    },
    duration(){
      return this.format(this.progress.duration);
    },
    loading(){
      if(isNaN(this.progress.currentTime) || isNaN(this.progress.duration) || this.progress.duration == 1){
        return true;
      }
      return false;
    }

  },
  components:{
    RangeSlider,

  },
  methods:{
    seekTo(progress){
      if(this.seekTimeout) clearTimeout(this.seekTimeout);
      this.seekTimeout = setTimeout( () => {
        this.$emit('seekTo', progress);
      }, 500, progress);

    },
    format(secs){
      let timearr = [];
      timearr.push((Math.floor(secs) % 60).toString());
      timearr.push((Math.floor(secs / 60) % 60).toString());
      timearr.push((Math.floor(secs / 60 / 60)).toString());
      for(let i = 0; i<timearr.length;i++){
        if(timearr[i].length < 2)
          timearr[i] = "0"+timearr[i];
      }

      return timearr[2] + ':' + timearr[1] + ':' + timearr[0];
    },
    onScroll () {
      if(this.hasMadeActiveChoice)
        return;
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 5) {
        return
      }
      let show = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
      if(!show) {this.hide(); this.activeChoice();}
      else this.show()

    },
    activeChoice(){
      this.hasMadeActiveChoice = true;
    },

    closedrawer(e){
      this.$el.children[0].style.transition = "";
      this.clientY = e.touches[0].clientY;
      this.height = parseInt(window.getComputedStyle(this.$el.children[0]).getPropertyValue('height').replace("px",""));

      this.$el.addEventListener('touchmove', e=>{
        e.preventDefault();
        let delta = this.clientY - e.touches[0].clientY
        if(delta > 10 || delta < -10){
          this.$el.children[0].style.height = this.height-delta+"px";
        }

      });

      this.$el.addEventListener('touchend', e=>{
        let deltaY = e.changedTouches[0].clientY - this.clientY;
        if(deltaY < -50){
          this.hide();
        }else if(deltaY > 40){
          this.show();
        }else{
          this.$el.children[0].style.transition = "height 0.2s";
          this.$el.children[0].style.height = "400px"
        }
      });
    },
    hide(){
      this.$el.children[0].style.transition = "height 0.2s";
      this.noShow = true;
      setTimeout(()=>{
        this.smallDrawer = true;
      }, 200);
    },
    show(){
      this.smallDrawer = false;
      this.noShow = false;
      this.$el.children[0].style.transition = "height 0.2s";
      this.$el.children[0].style.height = "400px"
    },
    volumeChange(volume){
      this.value = parseInt(volume.value);
      this.$emit('volumechange', {value: volume.value});
    },
  }
}
</script>

<style lang="css" scoped>
.progress-bar{
  width: 100%;
  height: 3px;
  background-color: darkgray;
}
.progress-fill{
  background-color: var(--primary);
  height: 100%;
}
.smallcontrols{
  margin-top: -40px;
}

.absolutify{
  top: 0;
  position: absolute;
}
.playimage, .imageoverlay{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 180px;
  z-index: -1;
}
.imageoverlay{
  background-color: black;
  opacity: 0.7;
}
.time{
  margin-top: 5px;
  margin-bottom: 0;
}
img{
  object-fit: cover;
}
.title, .theme{
  color: white;
}
#smallDrawer{
    touch-action: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--bgshade);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    box-shadow: 0 4px 4px rgba(0,0,0,0.3);
    z-index: 1;
    text-align: center;
    color: var(--text);
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 50px;
    box-sizing: border-box;
    width: 150px;
    border-radius: 0px 10px 10px 0px;

  padding: 20px;
  padding-bottom: 30px;
  height: 135px !important;
}
.hide{
  height: 0 !important;
  padding: 0 !important;
}
#close-player{
  margin: 0;
  margin-bottom: 20px;
}
#navicon{
  position: absolute;
  bottom: -5px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#navicon .material-icons-round{
  color: var(--text);
  font-size: 2.4rem;
  align-items: center;
}
.navbot{
  display: flex;
  color: var(--text);
  align-items: center;
  font-weight: bold;
}
.pos{
  top: 80px;
}
.player{
  touch-action: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--bgshade);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 400px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.3);
  z-index: 1;
  text-align: center;
  color: var(--text);
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 50px;
  box-sizing: border-box;
  border-radius: 0px 10px 10px 0px;
}
.pos{
  position: fixed;
  z-index: 10;
}

.little-image{
  width: 50px;
  height: 50px;
}
.songinfo{
  
}

.loader {
  border: 4px solid darkgray; /* Light grey */
  border-left: 4px solid var(--primary);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: auto;
  margin-bottom: -10px;
  margin-top: -10px;
}
.loadcontainer{
  min-width: 180px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


h2{
  font-size: 1.4rem;
}
.expandtext{
  font-weight: normal;
  font-size: 1rem;
}
.controls{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* background-color: var(--s-light); */
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgba(22,22,22,0.3),
  0 -2px 5px rgba(33,33,33,0.3); */

}
.playandvolume{
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;

}
.material-icons-round{
  font-size: 3rem;
  color: var(--text);
  border-radius: 50%;
  margin-left: -5px;
}
#play{
  margin: 5px;
  background-color: var(--bghade);
  border: none;
  border-radius: 50%;
  box-shadow: 0 -2px 5px var(--bgshade),
  0 2px 5px rgba(33,33,33,0.3);
  width: 50px;
  height: 50px;
  transition: box-shadow 0.1s, transform 0.1s;
}
#play:focus{
  outline: none;
}
#play:active{
  outline: none;
  transform: scale(0.9,0.9);
  box-shadow: 0 2px 5px var(--secondary),
  0 -2px 5px rgba(33,33,33,0.3);
}
#controlbuttons{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
#close{
  margin: 5px;
  background-color: var(--bgshade);
  border: none;
  border-radius: 50%;
  /* box-shadow: 0 -2px 5px var(--bgshade),
  0 2px 5px rgba(33,33,33,0.3); */
  width: 50px;
  height: 50px;
  transition: box-shadow 0.1s, transform 0.1s;
}
#close:focus{
  outline: none;
}
#close:active{
  outline: none;
  transform: scale(0.9,0.9);
  box-shadow: 0 2px 5px var(--secondary),
  0 -2px 5px rgba(33,33,33,0.3);
}
@media (orientation: portrait){

  #smallDrawer #navicon{
    justify-content: inherit;
    align-items: center;
    width: auto;
  }
  .container-100{
    width: 100%;
  }
  .pos{
    top: 0px;
  }
  .player, #smallDrawer{
    border-radius: 0;
  }
  #smallDrawer #navicon{
    background-color: var(--bg);
    padding-left: 5px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px var(--text),
    0 -2px 25px rgba(33,33,33,0.2);
  }
  #smallDrawer p.expandtext{
    margin: 0;
  }
  #smallDrawer{
    width: 120px;
    height: 135px !important;
    top: calc(100vh - 135px);
    background-color: inherit;
    box-shadow: none;
  }
  #smallDrawer .progress-bar, #smallDrawer .close{
    visibility: hidden;
  }
  #smallDrawer #play {
    background-color: var(--primary);
    width: 70px;
    height: 70px;
  }
  #smallDrawer #play i{
    color: white;
    margin: 0;
  }
  .smallcontrols{

  }

}


</style>


const infoHandler = {

  isPhone(){
    let hasTouchScreen = false;
    const UA = navigator.userAgent;
    if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
        let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            hasTouchScreen = (
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
    }

    if(hasTouchScreen) return true;
    return false;
  },

  isAppleDevice(){
    const UA = navigator.userAgent;
    return (UA.indexOf("iPhone") > -1) || (UA.indexOf("iPad") > -1);
  },

  isFacebookApp(){
    const UA = navigator.userAgent || navigator.vendor || window.opera;
    return (UA.indexOf("FBAN") > -1) || (UA.indexOf("FBAV") > -1);
  },

  getBrowser(){
    if(navigator.brave) return 'Brave';
    const UA = navigator.userAgent;
    const res = {};
    res.gecko = UA.match('Gecko/') != null;
    res.like_gecko = UA.match('like Gecko') != null;
    res.webkit = UA.match('AppleWebKit/') != null;
    res.chrome = UA.match('Chrome/') != null;
    res.chromium = UA.match('Chromium/') != null;
    res.crios = UA.match('CriOS/') != null;
    res.safari = UA.match('Safari/') != null;

    if(res.safari && res.webkit && !res.chrome && !res.chromium && !res.crios) return 'Safari';
    if(res.chrome || res.chromium || res.crios) return 'Chrome';
    if(res.gecko && !res.like_gecko) return 'Firefox';

    return 'en ukendt browser';
  }

}

export default infoHandler;

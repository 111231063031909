<template lang="html">
  <div class="wrap">
    <div id="header">
      <h1 @click="$router.push('/')"></h1>
      <div class="left">
        <router-link to="/profil" v-if="$auth.isAuthenticated">
        <SVGWrapper fill="#FFF" :width="50" :height="32" stroke="none" :svgData="svgicon_profile"/></router-link>
        <p v-if="!$auth.isAuthenticated" id="sign-up" @click="register">Opret profil</p>
        <p v-if="!$auth.isAuthenticated" id="sign-in" @click="login">Log ind</p>
        <p id="menu" class="material-icons-round" @click="toggleMenu">{{this.menuicon}}</p>
        <!-- <router-link to="/indstillinger">
        <SVGWrapper fill="#FFF" :width="50" :height="32" stroke="none" :svgData="svgicon"/></router-link> -->
      </div>

    </div>
    <div id="menu-drawer" :class="{'menu_open': menuopen}" @click="toggleMenu">

      <router-link to="/om-os"><div class="menuitem">
        <p>Om Stemman Podcast</p>
      </div></router-link>

      <router-link to="/sendeplan"><div class="menuitem">
        <p>Sendeplan</p>
      </div></router-link>

      <router-link to="/stemmerne"><div class="menuitem">
        <p>Mød Stemmerne</p>
      </div></router-link>

      <router-link to="/baggrund"><div class="menuitem">
        <p>Baggrund</p>
      </div></router-link>

      <br><br><br>

      <router-link to="/"><div class="menuitem" v-if="$route.path != '/'">
        <p>Tilbage til forsiden</p>
      </div></router-link>

    </div>

    <div id="spacer">

    </div>
  </div>

</template>

<script>
import SVGWrapper from '@/components/utils/SVGWrapper.vue';
import icons from '@/assets/icons/svg.js';

export default {
  data: function(){
    return{
      svgicon: icons.settings,
      svgicon_profile: icons.person,
      settings: {navbarHideOnScroll: false},
      menuicon: 'menu',
      menuopen: false,
    }
  },
  components:{
    SVGWrapper,
  },
  methods: {
    login(){
      window._paq.push(['trackContentInteraction', 'User', 'Login', 'logged-on-via-header']);
      this.$auth.loginWithRedirect()
        .then( () => {this.$emit('logged-on')} );
    },
    register() {
      window._paq.push(['trackContentInteraction', 'User', 'Register', 'register-via-header']);
      this.$auth.loginWithRedirect({ screen_hint: 'signup'})
        .then( () => {this.$emit('logged-on')} )
    },
    toggleMenu(){
      if(!this.menuopen) { this.menuicon = 'menu_open'; this.$emit('menu-open'); }
      else                        { this.menuicon = 'menu'; this.$emit('menu-close'); }

      this.menuopen = !this.menuopen;

    },
  }
}
</script>

<style lang="css" scoped>
#sign-in, #sign-up{
  margin: 0;
  padding: 5px;
  font-weight: bold;

}

#menu-drawer{
  width: 0;
  height: 100%;
  background-color: var(--primary);
  position: fixed;
  right: 0;
  transition: width 0.4s;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#menu-drawer a:first-child{
  margin-top: 50px;
}
.menuitem{
  color: white;
  padding: 10px;
  border-bottom: 2px solid var(--secondary-shade);
}
.menuitem p{
  white-space: nowrap;
}
a{
  text-decoration: none;
}
.menuitem:hover{
  cursor: pointer;
  background-color: var(--secondary-shade);
}
#menu-drawer.menu_open{
  width: 250px;
}
#sign-in:hover, #sign-up:hover{
  cursor: pointer;
  text-decoration: underline;
}
#sign-up{
  background-color: rgba(0,0,0,0.1);
  border-radius: 5px;
  margin-right: 5px;
}
#menu{
  margin-left: 10px;
  font-size: 2rem;
  background-color: var(--secondary-shade);
}
#spacer{
  margin-bottom: 45px;
}
#header{
  background-color: var(--secondary);
  background: var(--secondary-shade);
  color: white;
  padding: 5px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.3);
  position: fixed;
  height: 50px;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1rem;
  user-select: none;
  webkit-user-select: none;
}
.wrap{
  position: relative;
}

h1::before{
  background-image: url('~@/assets/logos/bomærke-white.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 2.5rem;
  border-radius: 5rem;
  height: 1rem;
  margin-left: 0rem;
  margin-right: 0zrem;
  content:"";
}
h1, h2{
  text-align: left;
  margin: 0;
  font-size: 1rem;
}
#header h1:hover{
  cursor: pointer;
}
h2{
  font-size: 0.9em;
  margin-left: 0.5em;
  color: var(--main-text);
}
.left{
  display: flex;
  align-items: center;
}
</style>

<template lang="html">
  <div class="logo-bottom">

    <div v-show="showHome">
      <router-link to="/"><span class="homebutton">Gå til Forsiden</span></router-link>
    </div>
    <img src="@/assets/logos/kvadrat.png" width="100px" height="65px" loading="lazy" alt=""/><br>
  <p>
    VID, VÆGT og VISDOM<br>
    <span class="citat">Fordi fordybelse er en fornøjelse!</span>
  </p>



  <p class="small">
    Musikken på denne tjeneste er<br> stillet til rådighed med tilladelse<br> fra <a href="https://www.ncb.dk" target="_blank">NCB</a>, <a href=" https://www.koda.dk" target="_blank">Koda</a> og <a href="http://www.ifpi.dk/" target="_blank">IFPI</a>.
  </p>
  <div class="darkarea">
    <div class="footer">
      <div class="infoBox">
        <h3>Følg med</h3>
        <div class="links">
          <a href="https://www.facebook.com/stemmanpodcast" target="_blank">
            <img class="small-img" src="@/assets/icons/facebook.png" alt="Billede af Facebook Logo" loading="lazy">
          </a>
          <a href="https://open.spotify.com/show/6HOstXFAW7YmLVtFJo9ACm?si=f86573aedd004bd0" target="_blank">
            <img class="small-img" src="@/assets/icons/spotify.png" alt="Billede af Spotify Logo" loading="lazy">
          </a>
        </div>
      </div>
      <div class="infoBox">
        <h3>Kontakt</h3>
        <div class="links">
          <p>Stemman Podcast</p>
          <p>Mail: <a href="mailto:redaktion@stemman.dk" target="_blank">redaktion@stemman.dk</a></p>
          <p>CVR: 41050667</p>
          <p>Bank: Jyske Bank</p>
          <p><a href="https://stemman.dk/kontakt" target="_blank">Kontakt</a></p>
        </div>
      </div>
      <div class="infoBox">
        <h3>Cookies og GDPR</h3>
        <div class="links">
          <p>Hjemmesiden benytter ikke tracking- eller marketing cookies</p>
          <p><a href="https://stemman.dk/privatlivspolitik" target="_blank">Læs om vores behandling af data</a></p>
        </div>
      </div>
    </div>
    <p class="cp">© Copyright Stemman, {{year}}</p>
  </div>
</div>
</template>

<script>

export default {
  components: {

  },
  computed: {
    showHome: function(){
      return this.$route.name !== 'Home'
    },
  },
  data(){
    let date = new Date();
    return {
      year: date.getFullYear()
    }

  }

}
</script>

<style lang="css" scoped>

.footer{
  grid-auto-flow: row;
  display: grid;
  grid-gap: 20px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 30px;
}
.infoBox{
  padding: 0px;
}
.homebutton{
  margin: 10px;
  display: block;
}
.logo-bottom{
  text-align: center;
  padding-top: 1rem;
  margin: auto;
  margin-bottom: 70px;
  margin-top: 70px;
}
.logo-bottom p{
  opacity: 0.7;
  margin-top: 0;
  margin-bottom: 0;
}
.small-img{
  width: 32px;
  height: 32px;
  margin: 5px;
}


img{
  margin-bottom: 0px;
}
a{
  color: var(--text);
}
.citat{
  opacity: 0.7;
  font-style: italic;
}
.small{
  font-size: 0.8rem;
}
@media only screen and (orientation: landscape) and (min-width: 700px){
  .infoBox{
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .footer{
    grid-auto-flow: column;
    grid-template-columns: 33% 33% 33%;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin: auto;
    justify-content: space-between;
  }
}
</style>

<template lang="html">
  <div class="popup-wrap" v-if="show">
    <div class="popup" :style="style" data-aos="fade-down" data-aos-duration="1500">
        <p>{{text}}</p>
    </div>

  </div>
</template>

<script>
export default {
  components:{

  },
  data(){
    return {
      show: true,
    }
  },
  props: ['text', 'bg', 'color'],
  computed: {
    style(){
      return `
        background-color: ${this.bg || 'rgb(248, 230, 120)'};
        color: ${this.color};
      `;

    }
  },
  mounted(){
    setTimeout(this.close, 5000);
  },
  methods: {
    close(){
      this.show= false;
    },
  }
}
</script>

<style lang="css" scoped>
.popup{
  position: fixed;
  width: 80%;
  min-height: 50px;
  z-index: 20;
  margin-top: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
  padding: 10px;
}
p{
  margin: 0;
}
.row{
  display: flex;
  gap: 20px;
}
.popup-wrap{
  position: relative;
  width: 80%;
  margin: auto;
}

</style>

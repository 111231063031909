import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "mainpages" */ '../views/Home.vue')
  },
  {
    path: '/indstillinger',
    name: 'Indstillinger',
    component: () => import(/* webpackChunkName: "mainpages" */ '../views/Other/Indstillinger.vue')
  },
  {
    path: '/install',
    name: 'Install',
    component: () => import(/* webpackChunkName: "mainpages" */ '@/components/elements/signups/InstallationGuide.vue')
  },
  {
    path: '/profil',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "mainpages" */ '../views/Other/Profile.vue')
  },
  {
    path: '/betaling/:item',
    name: 'Payments',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/Payments/PaymentView.vue')
  },
  {
    path: '/checkout/:item',
    name: 'Checkout Confirm',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/Payments/CheckoutConfirm.vue')
  },
  {
    path: '/om-os',
    name: 'OmOs',
    component: () => import(/* webpackChunkName: "menuitems" */ '../views/MenuPages/OmOs.vue')
  },
  {
    path: '/sendeplan',
    name: 'Sendeplan',
    component: () => import(/* webpackChunkName: "menuitems" */ '../views/MenuPages/Sendeplan.vue')
  },
  {
    path: '/stemmerne',
    name: 'Stemmerne',
    component: () => import(/* webpackChunkName: "menuitems" */ '../views/MenuPages/Stemmerne.vue')
  },
  {
    path: '/baggrund',
    name: 'Baggrund',
    component: () => import(/* webpackChunkName: "menuitems" */ '../views/MenuPages/Baggrund.vue')
  },
  {
    path: '/typequiz',
    component: () => import(/* webpackChunkName: "menuitems" */ '../views/Other/TypeQuiz.vue')
  },
  {
    path: '/nyhedsbrev',
    component: () => import(/* webpackChunkName: "menuitems" */ '../components/elements/signups/Newsletter.vue')
  },
  {
    path: '/nyhedsbrev/signup',
    component: () => import(/* webpackChunkName: "menuitems" */ '../components/elements/signups/SignedUp.vue')
  },
  {
    path: '/krypto',
    name: 'Krypto',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/Krypto.vue')
  },
  {
    path: '/demokrati',
    name: 'Demokrati',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/Demokrati.vue')
  },
  {
    path: '/livet-doeden-troen',
    name: 'LivetDødenTroen',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/LivetDødenTroen.vue')
  },
  {
    path: '/kaerlighed',
    name: 'Kærlighed',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/Kærlighed.vue')
  },
  {
    path: '/sidste-kunstrunde',
    name: 'SidsteKunstrunde',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/SidsteKunstrunde.vue')
  },
  {
    path: '/de-skjulte-konsekvenser/egholm-motorvejen',
    name: 'EgholmMotorvejen',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/Egholm.vue')
  },
  {
    path: '/martinus-tredje-testamente',
    name: 'Martinus',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/Martinus.vue')
  },
  {
    path: '/bogernes-verden',
    name: 'BøgernesVerden',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/BoegernesVerden.vue')
  },
  {
    path: '/techtorsdag',
    name: 'TechTorsdag',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/TechTorsdag.vue')
  },
  {
    path: '/sprogjornet',
    name: 'Sprogjornet',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/Sprogjornet.vue')
  },
  {
    path: '/riemanns-rytmer',
    name: 'RiemannsRytmer',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/RiemannsRytmer.vue')
  },
  {
    path: '/musik-med-seve',
    name: 'MusikMedSeve',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/LandingPages/MusikMedSeve.vue')
  },
  {
    path: '/podcast/:id',
    component: () => import(/* webpackChunkName: "landingpages" */ '../views/Other/PodcastView.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "mainpages" */ '../views/Other/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
   return { x: 0, y: 0 };
 },
})

export default router

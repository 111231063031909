import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import mitt from 'mitt'

import './assets/css/styles.css'


// Import the Auth0 configuration
import authconf from "../auth_config.json";
const {domain, clientId, audience} = authconf;

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.config.productionTip = false
const eventBus = mitt()
Vue.prototype.$eventBus = eventBus

new Vue({
  router,
  render: h => h(App),
  mounted() {
   AOS.init()
 },
}).$mount('#app')

// DEFINE ALL MARKUP FOR THE SVG ICONS

export default {
  notfound: 'viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{stroke-linejoin:round;stroke-width:2px;}</style></defs><title/><g data-name="36-404 error" id="_36-404_error"><polyline class="cls-1" points="12 25 1 25 1 21 1 1 31 1 31 21 31 25 20 25"/><line class="cls-1" x1="22" x2="25" y1="31" y2="31"/><line class="cls-1" x1="7" x2="10" y1="31" y2="31"/><polygon class="cls-1" points="22 31 10 31 12 25 20 25 22 31"/><line class="cls-1" x1="1" x2="31" y1="21" y2="21"/><polyline class="cls-1" points="6 8 6 12 9 12 11 12"/><polyline class="cls-1" points="9 8 9 12 9 15"/><polyline class="cls-1" points="21 8 21 12 24 12 26 12"/><polyline class="cls-1" points="24 8 24 12 24 15"/><rect class="cls-1" height="5" width="5" x="13" y="9"/></g></svg>',

  radio: '<height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="M6.47 12.3C5.02 12.87 4 14.33 4 16v24c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V16c0-2.21-1.79-4-4-4H16.61l16.53-6.67L31.76 2 6.47 12.3zM14 40c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm26-16h-4v-4h-4v4H8v-8h32v8z"/></svg>',

  library: '<id="Layer_1" style="enable-background:new 0 0 30 30;" version="1.1" viewBox="0 0 30 30" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><line style="stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" x1="6" x2="24" y1="7" y2="7"/><line style="stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" x1="7" x2="23" y1="3" y2="3"/><path d="M24,10H6c-1.105,0-2,0.895-2,2v14c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V12C26,10.895,25.105,10,24,10z M19,15.77  c0,0.148-0.105,0.276-0.25,0.305L15,16.898c0,0,0,3.819,0,5.279C15,23.662,14.493,25,12.733,25c-1.432,0-2.233-0.791-2.233-2  c0-2.918,3.5-1.753,3.5-3v-6l4.643-0.994C18.828,12.969,19,13.11,19,13.299V15.77z"/></svg>',

  theme: 'viewBox="0 0 28 30" xmlns="http://www.w3.org/2000/svg"><path d="m14 7a7.00818 7.00818 0 0 0 -7 7 1 1 0 0 0 2 0 5.00588 5.00588 0 0 1 5-5 1 1 0 0 0 0-2z" fill="none"/><path d="m17 25h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2z"/><path d="m17 28h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2z"/><path d="m14 4a.99974.99974 0 0 0 1-1v-2a1 1 0 0 0 -2 0v2a.99974.99974 0 0 0 1 1z"/><path d="m3 13h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"/><path d="m6.707 6.707a.99962.99962 0 0 0 0-1.41406l-1.5-1.5a.99989.99989 0 0 0 -1.414 1.41406l1.5 1.5a.99963.99963 0 0 0 1.41406 0z"/><path d="m27 13h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"/><path d="m23.707 3.793a.99962.99962 0 0 0 -1.41406 0l-1.5 1.5a.99989.99989 0 1 0 1.41406 1.414l1.5-1.5a.99962.99962 0 0 0 0-1.414z"/><path d="m14 5.3335a8.50145 8.50145 0 0 0 -4.333 15.81494v1.85156a.99974.99974 0 0 0 1 1h6.666a.99974.99974 0 0 0 1-1v-1.85156a8.50145 8.50145 0 0 0 -4.333-15.81494zm0 3.6665a5.00588 5.00588 0 0 0 -5 5 1 1 0 0 1 -2 0 7.00818 7.00818 0 0 1 7-7 1 1 0 0 1 0 2z"/></svg>',

  home: 'viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z"/></svg>',

  settings: 'viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m0 0h24v24h-24z" fill="none"/><path d="m19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65c-.03-.24-.24-.42-.49-.42h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64zm-7.43 2.52c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg>',

  person: 'viewBox="0 0 24 24" width="24px" ><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/></svg>',

  crown: 'viewBox="0 0 128 128" width="128" xmlns="http://www.w3.org/2000/svg"><path d="m112 36c-6.629 0-12 5.375-12 12 0 1.68.352 3.273.973 4.727l-16.973 7.273-14.199-25.555c3.679-2.054 6.199-5.937 6.199-10.445 0-6.625-5.371-12-12-12s-12 5.375-12 12c0 4.508 2.52 8.391 6.199 10.445l-14.199 25.555-16.973-7.273c.621-1.454.973-3.047.973-4.727 0-6.625-5.371-12-12-12s-12 5.375-12 12 5.371 12 12 12c.93 0 1.822-.133 2.695-.328l9.305 40.328v8c0 4.422 3.582 8 8 8h56c4.418 0 8-3.578 8-8v-8l9.309-40.328c.867.203 1.761.328 2.691.328 6.629 0 12-5.375 12-12s-5.371-12-12-12zm-48-16c2.207 0 4 1.797 4 4s-1.793 4-4 4-4-1.797-4-4 1.793-4 4-4zm-52 28c0-2.203 1.793-4 4-4s4 1.797 4 4-1.793 4-4 4-4-1.797-4-4zm80 60h-56v-8h56zm1.633-16h-59.266l-7.027-30.437 13.508 5.789c1.023.437 2.093.648 3.148.648 2.828 0 5.547-1.5 6.996-4.117l13.008-23.406 13.008 23.406c1.449 2.617 4.168 4.117 6.996 4.117 1.055 0 2.125-.211 3.148-.648l13.508-5.789zm18.367-40c-2.207 0-4-1.797-4-4s1.793-4 4-4 4 1.797 4 4-1.793 4-4 4z"/></svg>',

  share: 'viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92-1.31-2.92-2.92-2.92z"/></svg>',

}

<template lang="html">
  <div class="playercontroller">
    <Player v-if="showPlayer"
      :theme="playing_info.theme"
      :title="playing_info.title"
      :imgsrc="playing_info.imgsrc"
      :access_level="playing_info.access_level"
      :isPlaying="isPlaying"
      :progress="progress"
      :errorMsg="errorMsg"
      @stop="stopRadio"
      @play="startRadio"
      @close="closeRadio"
      @seekback="seekRadioBackward"
      @seekforward="seekRadioForward"
      @seekTo="seekRadioTo"
      ref="player"/>
    <audio id="audio" @ended="audioEnd">
      <source src="" type="audio/mpeg">
      Din browser understøtter ikke HTML5 Audio tag.
    </audio>
  </div>
</template>

<script>
import Player from '@/components/main/Player.vue';
import mediaHandler from '@/components/utils/mediaHandler.js';
import dataHandler from '@/components/utils/dataHandler.js';

export default {
  components:{
    Player,
  },
  data(){
    return{
      isPlaying: false,
      showPlayer: false,
      audioElement: null,
      playing_info: {theme: null, title: null, imgsrc: null, access_level: 0},
      progress: {currentTime: 0, duration: 1, percentage: 0},
      interval: null,
      datastoreconfig: {counter: 0, updateOnMax: 10, storeOnMax: 60},
      activePodcast: null,
      errorMsg: '',
    }
  },
  mounted(){
    this.audioElement = this.$el.querySelector('#audio');

    this.$eventBus.on('play', (args) => {
      this.openRadio(args);
      window._paq.push(['trackEvent', 'Audio', 'Play Event', args.song.transmission.title]);
    })
  },

  methods: {
    audioEnd(){
      dataHandler.updateListenPercentageOnPodcast(this.activePodcast.podcast.id, this.progress);
      this.$emit('audio-ended', this.activePodcast);
      window._paq.push(['trackEvent', 'Audio', 'Finished', this.activePodcast.transmission.title]);
    },
    updateProgress(){
      if(!this.audioElement)
        this.progress = {currentTime: 0, duration: 1, percentage: 0};
      this.progress = {currentTime: this.audioElement.currentTime, duration: this.audioElement.duration, percentage: Math.floor(this.audioElement.currentTime / this.audioElement.duration)};
      this.datastoreconfig.counter++;
      let localupdatecount = this.datastoreconfig.counter % this.datastoreconfig.updateOnMax;
      let localstorecount = this.datastoreconfig.counter % this.datastoreconfig.storeOnMax;

      if(localstorecount === 0){
        dataHandler.addListenedPodcastData(this.activePodcast);
        dataHandler.updateListenPercentageOnPodcast(this.activePodcast.podcast.id, this.progress);
      }
      if(localupdatecount === 0){
        this.$emit('updated-listen-progress', {id: this.activePodcast.podcast.id, progress: this.progress});
        this.$eventBus.emit('updated-listen-time', {id: this.activePodcast.podcast.id, progress: this.progress});
      }
    },
    closeRadio(){
      clearInterval(this.interval);
      mediaHandler.pauseAudio();
      mediaHandler.destroyMediaSession();

      try{
        dataHandler.updateListenPercentageOnPodcast(this.activePodcast.podcast.id, this.progress);
      }catch(err){
        console.log(err);
      }

      this.showPlayer = false;
      this.isPlaying = false;
    },
    startRadio(){
      mediaHandler.playAudio()
        .then(() => {
          this.isPlaying = true;
          if(this.interval === null)
            this.interval = setInterval(this.updateProgress, 1000);
        })
        .catch(e => {
          console.log(e);
        });


    },
    stopRadio(){
      this.isPlaying = false;
      mediaHandler.pauseAudio();
      clearInterval(this.interval);
      this.interval = null;
    },
    seekRadioForward(){
      mediaHandler.skipAudio(30);
    },
    seekRadioBackward(){
      mediaHandler.skipAudio(-30);
    },
    seekRadioTo(percent){
      mediaHandler.setAudioTime(this.progress.duration * percent / 100);
    },
    async openRadio(payload){

      this.playing_info = {
        theme: payload.song.podcast.theme_name,
        title: payload.song.transmission.title,
        imgsrc: payload.song.transmission.image,
        access_level: parseInt(payload.song.podcast.access_level)
      };

      mediaHandler.setupMediaSession();
      mediaHandler.updateMediaSessionOptions({
        title: this.playing_info.title,
        artist: 'Stemman',
        album: this.playing_info.theme,
        artwork: [{src: this.playing_info.imgsrc, sizes: '512x512', type: 'image/png'}]
      });

      if(payload.song.podcast.access_level == 0){
        this.audioElement.src = 'https://api.stemman.dk/podcasts.php?file=' + payload.song.podcast.audio_id;
        // PLAY
        this.attemptPlay(payload);
      } else{
        try{
           const token = await this.$auth.getTokenSilently();
           mediaHandler.getRestrictedFile(token,
             'https://api.stemman.dk/podcasts.php?file=' + payload.song.podcast.audio_id +'&access-level='+payload.song.podcast.access_level)
             .then(() => {
               this.attemptPlay(payload);
             });
         }
         catch(e){
           if(e == 'Error: Login required'){this.errorMsg = "Du skal logge ind for at lytte til denne episode."}
           else{this.errorMsg = "Noget gik galt. Kontakt webmaster@stemman.dk."}
           return;
         }
    }



      this.showPlayer = true;

    },

    attemptPlay(payload){
      let time = 0;
      // CHECK IF CONTINUE
      if(payload.song.progress){
          time = (payload.song.progress.currentTime / payload.song.progress.duration) < 0.9 ? payload.song.progress.currentTime : 0;
      }

      mediaHandler.setAudioTime(time);
      mediaHandler.playAudio()
        .then(() => {
          this.isPlaying = true;

          if(this.interval === null)
            this.interval = setInterval(this.updateProgress, 1000);

          this.activePodcast = payload.song

        })
      .catch(e => {
        this.errorMsg = "Noget gik galt. Kontakt webmaster@stemman.dk. Fejl: " + e.toString();
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>

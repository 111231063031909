<template lang="html">
  <div class="">
    <div class="range-slider-container">
      <div class="range-slider-bg">
            <div class="range-slider-thumb"  :style="{ width: `calc(${25*(50-localProgress.percentage)/100}px + ${localProgress.percentage}%)` }"></div>
      </div>

      <input type="range" @input="change" v-model="localProgress.percentage">
    </div>
  </div>

</template>

<script>
export default {
  props: ['progress'],
  computed: {
    localProgress: {
      get() {return this.progress},
    }
  },
  methods: {
    change(e){
      this.$emit('change', e.target.value);
    }
  }
}
</script>

<style lang="css" scoped>
.range-slider-container{
  width: 100%;
  height: 15px;
  position: relative;
}
.range-slider-bg{
  width: 100%;
  height: 3px;
  background-color: darkgray;
  top: 50%;
  position: absolute;
}
.range-slider-thumb{
  background-color: var(--primary);
  height: 3px;

}
input[type=range] {

  position: relative;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--primary);
  width: 25px;
  height: 15px;
  border-radius: 5px;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}


</style>

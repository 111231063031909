
const dataHandler = {
   getPodcastData(){
    let datastorage = JSON.parse(localStorage.getItem('podcast_data') || '{}');
    return datastorage;
  },
  addPodcastData(obj){
    let datastorage = JSON.parse(localStorage.getItem('podcast_data') || '{}');
    let d = Object.assign(datastorage, obj);
    localStorage.setItem('podcast_data', JSON.stringify(d));
  },
  // RETURNS ARRAY
  getListenedPodcastsData(){
    let datastorage = JSON.parse(localStorage.getItem('podcast_data') || '{}');
    let listened = datastorage.listened || [];
    return listened;
  },
  // RETURNS OBJECT OR undefined
  getListenedPodcastData(id){
    let listened = this.getListenedPodcastsData();
    for(let pod of listened){
      if(pod.podcast.id === id) return pod;
    }
    return undefined;
  },
  // RETURNS {data, index} or undefined
  getListenedPodcastDataAndIndex(id){
    let listened = this.getListenedPodcastsData();
    for(let i = 0; i < listened.length; i++){
      if(listened[i].podcast.id === id)
        return {data: listened[i], index: i};
    }
    return undefined;
  },

  // PUSHES A NEW LISTED PODCAST
  addListenedPodcastData(data){
    let datastorage = this.getPodcastData();
    let listened = datastorage.listened || [];
    if(data.listenPercentage == undefined) data.progress = {currentTime: 0, duration: 1};
    if(listened.filter(e => e.podcast.id == data.podcast.id).length >= 1)
      return;
    data.timestamp = Date.now();
    listened.push(data);
    datastorage.listened = listened;
    localStorage.setItem('podcast_data', JSON.stringify(datastorage));
  },

  updateListenPercentageOnPodcast(id, progress){
    let datastorage = this.getPodcastData();
    let element = this.getListenedPodcastDataAndIndex(id);
    if(!element){
      throw 'No podcast with id '+ (id) +' in localStorage';
    }
    element.data.progress = {currentTime: progress.currentTime, duration: progress.duration};
    element.data.timestamp = Date.now();
    datastorage.listened[element.index] = element.data;
    localStorage.setItem('podcast_data', JSON.stringify(datastorage));
  }
}
export default dataHandler;

<template lang="html">
  <div :class="{'wrap': true, 'fullwidth': fullwidth}">
    <button type="button" :aria-label="text" :class="{'active': !disabled, 'disabled': disabled}" :style="style" @click="pressed">
      <p><span class="material-icons-round" :style="theicon">{{icon}}</span>{{text}}</p>
    </button>
  </div>

</template>

<script>

export default {
  name: 'StdButton',
  props: {
    text: String,
    color: String,
    bg: String,
    border: String,
    minwidth: String,
    minheight: String,
    background: String,
    icon: String,
    disabled: Boolean,
    fullwidth: Boolean,
  },
  computed: {
    style(){
      return `
        background: ${this.background || 'none'};
        background-color: ${this.bg || 'rgba(0,0,0,0)'};

        border: 3px solid ${this.border || 'rgba(0,0,0,0)'};
        color: ${this.color || 'inherit'};
        min-width: ${this.minwidth || 'auto'};
        min-height: ${this.minheight || 'auto'};
      `;

    },
    theicon(){
      return `display: ${this.icon ? 'inline' : 'none'}`;
    }

  },
  methods:{
    pressed(){
      window._paq.push(['trackContentInteraction', 'Util', 'Button Press', this.text.toString()]);
      this.$emit('click');
    }
  }
}
</script>

<style lang="css" scoped>
.fullwidth{
  width: 100%;
}
.material-icons-round{
  font-size: inherit;
  position: relative;
  top: 0.2rem;
}
p{
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 5px;
}
i{
  margin: 0;
  padding: 0;
  line-height: 0;
  font-size: 1rem;
}

  button{
    padding: 2px;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: inherit;
    box-shadow: 3px 3px 9px 0px rgba(0,0,0,0.3);
    font-weight: inherit;

  }
  button.active:active, button.active:hover{
    filter: brightness(110%);
  }
  button.active:hover{
    cursor: pointer;
  }
  button.disabled:hover{
    cursor: not-allowed;
  }
</style>

<template lang="html">
  <div class="popupbar" v-if="!ignore">


    <div class="contain">
      <div class="logo">
        <img src="/favicon.png" alt="billede af app-logo">
      </div>
      <div class="text">
        <h3>Nem installation</h3>
        <p>Følg guide og hent app til mobil og computer.</p>
      </div>
      <div class="buttons">
        <StdButton
          text="Se Installationsguide"
          :fullwidth="true"
          minwidth="100%"
          minheight="45px"
          color="white"
          bg="var(--primary)"
          @click="gotoInstall"/>
        <StdButton
          text="Ikke nu"
          minwidth="100%"
          :fullwidth="true"
          minheight="45px"
          @click="close"/>
      </div>
    </div>


  </div>
</template>

<script>
import StdButton from '@/components/StdButton.vue';
let ignore = localStorage.getItem("ignore_install") || false;
export default {
  data(){
    return {
      ignore,
    }
  },
  components: {
    StdButton,
  },
  methods:{
    gotoInstall(){
      this.ignore = true;
      localStorage.setItem('ignore_install', true);
      this.$router.push('/install');
    },
    close(){
      localStorage.setItem('ignore_install', true);
      this.ignore = true;
    }
  }
}
</script>

<style lang="css" scoped>
.popupbar{
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;

  background-color: var(--bgshade);
  box-shadow: 0 -2px 6px rgba(0,0,0,0.3);
  animation-name: popup;
  animation-duration: 4s;
}
.contain{
  display: grid;
  grid-template-columns: 1fr 4fr 2fr;
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  margin: auto;
  gap: 10px;
}
.popupbar h3, .popupbar p{
  margin: 0;
  margin-top: 10px;
}
.logo img{
  width: 100%;
  max-width: 80px;
  margin-top: 5px;
}
.buttons{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
@keyframes popup {
  0% {bottom: -300px}
  75% {bottom: -300px}
  100% {bottom: 0}
}
@media (orientation: portrait){
  .contain{
    grid-template-columns: 1fr 3fr;
  }
  .buttons{
    grid-column-start: 1;
    grid-column-end: 3;

  }
}
</style>

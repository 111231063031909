<template>
  <div id="app" class="flex-down-start">
    <Header v-show="!hideHeader"
     @menu-open="() => {this.menu_open = true}" 
     @menu-close="() => {this.menu_open = false}"
    />

    <!-- <HumbleBar/> -->

    <div style="padding: 5px; text-align: center; background-color: yellow" v-if=" info.browser == 'Firefox' ">
    Vi oplever i øjeblikket problemer i <span class="bold">Firefox browseren</span>. 
    Vi anbefaler at du benytter Chrome eller Safari indtil problemet er løst.
    </div>

    <div id="app-content" :class="{'blur': menu_open}">
      
      <PlayerController @updated-listen-progress="updateListenProgress" @audio-ended="nextEpisode" />

      <!-- <PWAPromo /> -->

      <Popupbar text="Appen blev installeret" bg="#2ed752" v-if="installedApp"/>
      <InstallationPopup />

      <transition name="fade" mode="out-in">
        <router-view
          :podcasts="podcasts"
          :user="user"
          @get-purchases="getPurchases"
        />
      </transition>

      <div id="footer">
        <div class="container">
          <FAQ/>
        </div>

        <div class="center-flex padding-10" v-if="$route.name && !$route.name.includes('Install')">
          <router-link to="/install" aria-label="Link til installationsguide">
          <StdButton class="bold" text="Installationsguide" bg="var(--primary)" icon="download" color="white" minwidth="200px" minheight="50px"/>
          </router-link>
        </div>

        <Logout @logged-out="removePurchases"/>

        <Footer />
      </div>
    </div>

  </div>
</template>

<script type="text/javascript">
import axios from 'axios';
import dataHandler from '@/components/utils/dataHandler.js';

// import Popupbar from '@/components/elements/PopupbarMessage.vue';
import Header from '@/components/main/Header.vue';
import Footer from '@/components/main/Footer.vue';
import PlayerController from '@/components/utils/PlayerController.vue';

import StdButton from '@/components/StdButton.vue';

import FAQ from '@/components/main/FAQ.vue';
import Logout from '@/components/users/Logout.vue';

// import PWAPromo from '@/components/elements/PWAPromo.vue';
import Popupbar from '@/components/elements/Popupbar.vue';
import InstallationPopup from '@/components/elements/signups/InstallationPopup.vue';
// import HumbleBar from './components/popups/HumbleBar.vue';

import infoHandler from '@/components/utils/infoHandler.js';

  export default {
    components: {
    Header,
    // Popupbar,
    Footer,
    PlayerController,
    StdButton,
    Logout,
    FAQ,
    // PWAPromo,
    Popupbar,
    InstallationPopup,
    // HumbleBar
},
    computed: {
      hideHeader(){
        return this.$route.name ? this.$route.name.includes('hide-header') : false;
      }
    },
    data(){
      return {
        podcasts: {},
        user: {purchasedItems: []},
        installedApp: false,
        menu_open: false,
        info: {browser: ""}
      }
    },
    beforeMount(){
      this.info.browser = infoHandler.getBrowser();
    },
    async mounted(){
      window.addEventListener('appinstalled', this.appWasInstalled);

      window.stemmanApp = this;
      setTimeout(this.getPurchases, 5000);

      let purchases = dataHandler.getPodcastData();
      purchases = purchases.purchases || [];
      this.user.purchasedItems = purchases;

      let pods = await this.getPodcasts();
      let listened = dataHandler.getListenedPodcastsData();
      for(let listenedpod of listened){
        for(let podcast of Object.entries(pods)){
          let updatedpodcast = podcast[1].map(pod => {
            if(pod.podcast.id == listenedpod.podcast.id){
              pod.progress = listenedpod.progress;
            }
            return pod;
          });
          pods[podcast[0]] = updatedpodcast;
        }
      }

      this.podcasts = pods;
    },
    methods: {
      appWasInstalled(){
        this.installedApp = true;
        window._paq.push(['trackEvent', 'PWA', 'Install', 'success']);
      },
      nextEpisode(current){

        let sel = null;
        switch (current.podcast.theme_name) {
          case 'Riemanns Rytmer':
            sel = getNewerThenLater(this.podcasts.riemannsRytmer, current);
            break;
          case 'Musik med SEVE':
            sel = getNewerThenLater(this.podcasts.musikMedSeve, current);
            break;
          case 'Sidste kunstrunde med Kærulf':
              sel = getNewerThenLater(this.podcasts.sidsteKunstrunde, current);
            break;
          case 'Martinus Testamente':
              sel = getNewerThenLater(this.podcasts.martinus, current);
            break;
          case 'Bøgernes Verden':
              sel = getNewerThenLater(this.podcasts.martinus, current);
            break;
          case 'Techtorsdag':
              sel = getNewerThenLater(this.podcasts.techtorsdag, current);
            break;
          case 'Språgjørnet':
              sel = getNewerThenLater(this.podcasts.sprogjornet, current);
            break;
          case 'Krypto':
            sel = getNewerThenLater(this.podcasts.krypto, current);
            break;  
          case 'Demokratiet':
            sel = getNextReleased(this.podcasts.demokratiet, current);
            break;  
          case 'Kærlighed':
              sel = getNewerThenLater(this.podcasts.kaerlighed, current);
              break;  
          case 'De Skjulte Konsekvenser - Egholm-motorvejen':
              sel = getNextReleased(this.podcasts.egholm, current);
              break;
          case 'Livet, Døden og Troen':
            if(current.transmission.title.match(/Del I$/g)){
              let p = this.podcasts.livet.filter(e => {
                if(e.transmission.title.match(/Del I$/g)) return true;
                return false;
              });
              sel = getNewerThenLater(p, current);
            }
            else if(current.transmission.title.match(/Del II$/g)){
              let p = this.podcasts.livet.filter(e => {
                if(e.transmission.title.match(/Del II$/g)) return true;
                return false;
              });
              sel = getNewerThenLater(p, current);
            }
            else if(current.transmission.title.match(/Del III$/g)){
              let p = this.podcasts.livet.filter(e => {
                if(e.transmission.title.match(/Del III$/g)) return true;
                return false;
              });
              sel = getNewerThenLater(p, current);
            }
            break;
          default:

        }

        if(sel){
          this.$eventBus.emit('play', { song: sel });
        }

        function getNewerThenLater(list, current){
          let sel = getNewerUnheard(list, current);
          if(!sel){
            sel = getLaterUnheard(list, current);
          }
          if(!sel){
            sel = getNext(list, current);
          }
          return sel;
        }

        function getNext(list, curr){
          let selected = {podcast: {id: Number.MAX_VALUE}, temp: true};
          for(let pod of list){
            if(pod.podcast.id > curr.podcast.id && pod.podcast.id < selected.podcast.id){
              selected = pod;
            }
          }
          if(selected.temp) return null;
          return selected;
        }

        function getNextReleased(list, curr){
          let selected = {broadcastDateStart: "9000-04-01T18:02:00.000Z"};
          for(let pod of list){
            if(new Date(pod.broadcastDateStart).getTime() > new Date(curr.broadcastDateStart).getTime() && new Date(pod.broadcastDateStart).getTime() < new Date (selected.broadcastDateStart).getTime()){
              selected = pod;
            }
          }
          return selected;
        }

        function getNewerUnheard(list, curr){
          let listened = dataHandler.getListenedPodcastsData();
          let selected = {podcast: {id: Number.MAX_VALUE}, temp: true};
          for(let pod of list){
            if(pod.podcast.id > curr.podcast.id && pod.podcast.id < selected.podcast.id) {
              let a = listened.filter(e => e.podcast.id == pod.podcast.id);
              if(a.length == 0){
                  selected = pod;
              }
            }
          }
          if(selected.temp) return null;
          return selected;
        }
        function getLaterUnheard(list, curr){
          let listened = dataHandler.getListenedPodcastsData();
          let selected = {podcast: {id: 0}, temp: true};
          for(let pod of list){
            if(pod.podcast.id < curr.podcast.id && pod.podcast.id > selected.podcast.id) {

              let a = listened.filter(e => e.podcast.id == pod.podcast.id);

              if(a.length == 0){
                  selected = pod;
              }
            }
          }
          if(selected.temp) return null;
          return selected;
        }
      },
      async getPurchases(){
        if(!this.$auth.isAuthenticated) {this.removePurchases(); setTimeout(this.getPurchases, 1000); return false}
        try{
          const token = await this.$auth.getTokenSilently();
          const res = await axios.get('https://api.stemman.dk/stripe-payments/getPurchases.php', {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          });
          this.user.purchasedItems = res.data.items;
          //update localStorage...
          dataHandler.addPodcastData({purchases: res.data.items});

        }catch(e){
          console.log(e);
        }
        return true;
      },
      removePurchases(){
        this.user.purchasedItems = [];
        dataHandler.addPodcastData({purchases: []});
      },
      updateListenProgress(data){
        let updatedpodcasts = {};
        for(let podcast of Object.entries(this.podcasts)){
          let updatedpodcast = podcast[1].map(pod => {
            if(pod.podcast.id == data.id){
              pod.progress = data.progress;
            }
            return pod;
          });
          updatedpodcasts[podcast[0]] = updatedpodcast;
        }
        this.podcasts = updatedpodcasts;
      },
      async getPodcasts(){
        try{
          const response = await axios.get('https://api.stemman.dk/podcasts.php');
          const data = response.data.data;
          const riemannsRytmer = data.filter(e => e.podcast.theme_name == 'Riemanns Rytmer') || [];
          const musikMedSeve = data.filter(e => e.podcast.theme_name == 'Musik med SEVE') || [];
          const livet = data.filter(e => e.podcast.theme_name == 'Livet, Døden og Troen').reverse() || [];
          const sidsteKunstrunde = data.filter(e => e.podcast.theme_name == 'Sidste kunstrunde med Kærulf').reverse() || [];
          const martinus = data.filter(e => e.podcast.theme_name == 'Martinus Testamente').reverse() || [];
          const boegernesVerden = data.filter(e => e.podcast.theme_name == 'Bøgernes Verden').reverse() || [];
          const techtorsdag = data.filter(e => e.podcast.theme_name == 'Techtorsdag').reverse() || [];
          const sprogjornet = data.filter(e => e.podcast.theme_name == 'Språgjørnet').reverse() || [];
          const kaerlighed = data.filter(e => e.podcast.theme_name == 'Kærlighed').reverse() || [];
          const krypto = data.filter(e => e.podcast.theme_name == 'Krypto').reverse() || [];
          const demokratiet = data.filter(e => e.podcast.theme_name == 'Demokratiet').reverse() || [];
          const egholm = data.filter(e => e.podcast.theme_name == 'De Skjulte Konsekvenser - Egholm-motorvejen').reverse() || [];
          const podcasts = {
            riemannsRytmer,
             musikMedSeve, 
             livet, 
             sidsteKunstrunde, 
             martinus, 
             boegernesVerden, 
             techtorsdag, 
             sprogjornet, 
             kaerlighed,
             krypto,
             demokratiet,
             egholm,
          };
          return podcasts;
        }
        catch(err){
          // OFFLINE?
          console.log(err);
        }

      },

    }
  }
</script>


<style>
/* nunito-regular - latin */
/* @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/nunito-v25-latin-regular.woff2') format('woff2'),
  }
  /* nunito-700 - latin */
  /* @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/nunito-v25-latin-700.woff2') format('woff2'),
  } */

    #app {
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: var(--text);

    overflow: hidden;
    min-height:100vh;
  }
  #app-content{
    width: 100vw;
  }

  #footer{
    background-color: var(--bgshade);
    width: 100%;
    margin-top: 10px;
    /* content-visibility: auto;
    contain-intrinsic-size: 1px 1000px; */
  }
  @media (orientation: portrait) {
    .blur{
    filter: blur(10px);
    }
    #app-content{
      transition: filter 0.4s ease-out;
    }
  }
</style>

<template lang="html">
  <div class="outer">
    <!-- Check that the SDK client is not currently loading before accessing is methods -->
   <div v-if="!$auth.loading">
     <!-- show logout when authenticated -->
     <center v-if="$auth.isAuthenticated">
       <p>Du er logget ind som {{$auth.user.name}}</p>
       <StdButton text="Log ud" @click="logout" color="var(--text)" />
     </center>

   </div>
  </div>
</template>

<script>
import StdButton from '@/components/StdButton.vue';

export default {
  components: {
    StdButton,
  },
  methods: {
    // Log the user out
    logout() {
      this.$emit('logged-out');
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
}
</script>

<style lang="css" scoped>
.outer{
  width: 100%;
}
</style>



export default {
  mediaSessionOptions: {
    title: 'Stemman',
    artist: 'Stemman',
    album: 'Stemman',
    artwork: [{src: 'https://dummyimage.com/512x512', sizes: '512x512', type: 'image/png'}],
  },
  checkCapability: function(){
    if ('mediaSession' in navigator) {
      return true;
    }else{
      return false;
    }
  },

  getRestrictedFile : function(token, path){
    let audioElement = document.querySelector('#audio');
    let mimeCodec = 'audio/mpeg;';

    return new Promise((resolve, reject) => {

      var xhr = new XMLHttpRequest;
      xhr.open('get', path);
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function () {
        if(xhr.response.byteLength < 700) {throw new Error('too short response')}
        const blob = new Blob([xhr.response], { type: mimeCodec });
        audioElement.src = window.URL.createObjectURL(blob);
        resolve();
      };
      xhr.onerror = function (e){
        reject(e);
      };

      xhr.send();

    });


  },

  playAudio: function(){

    let audioElement = document.querySelector('#audio');
    let playPromise = audioElement.play();

    if (playPromise !== undefined) {
      playPromise.then(() => {
        if ('mediaSession' in navigator) {
          navigator.mediaSession.playbackState = "playing";
        }
      })
      .catch(() => {return})
    }
    return playPromise;

  },

  pauseAudio: function(){
    let audioElement = document.querySelector('#audio');
    audioElement.pause();
    // audioElement.src = '';

    if ('mediaSession' in navigator) {
      navigator.mediaSession.playbackState = "paused";
    }

  },

  skipAudio: function(amount){
    let audioElement = document.querySelector('#audio');
    let curtime = audioElement.currentTime;
    curtime += amount;
    if(curtime > 0){
    audioElement.currentTime = curtime;
    }
  },

  setAudioTime: function(amount){
    let audioElement = document.querySelector('#audio');
    if(amount > 0){
    audioElement.currentTime = amount;
    }
  },

  updateMediaSessionOptions: function(options){
    this.mediaSessionOptions = Object.assign(this.mediaSessionOptions, options);

    if ('mediaSession' in navigator) {
      /* eslint-disable-next-line */
      navigator.mediaSession.metadata = new MediaMetadata(this.mediaSessionOptions);
    }


  },

  setupMediaSession: function(){

    if ('mediaSession' in navigator) {
      /* eslint-disable-next-line */
      navigator.mediaSession.metadata = new MediaMetadata();
      try {
        navigator.mediaSession.setActionHandler('play', () => {

              let audioElement = document.querySelector('#audio');
              let playPromise = audioElement.play();

              if (playPromise !== undefined) {
                playPromise.then(() => {
                  window.stemmanApp.isPlaying = true;
                  if ('mediaSession' in navigator) {
                    navigator.mediaSession.playbackState = "playing";
                  }
                })
                .catch(error => {
                  console.log(error);
                });
              }
        });
        navigator.mediaSession.setActionHandler('pause', () => {
          let audioElement = document.querySelector('#audio');
          audioElement.pause();
          window.stemmanApp.isPlaying = false;

          if ('mediaSession' in navigator) {
            navigator.mediaSession.playbackState = "paused";

          }

        });
       } catch (error) {
        console.log(`The media session action is not supported yet.`);
       }

    }
  },

  destroyMediaSession: function(){
    let audioElement = document.querySelector('#audio');
    audioElement.src = "";
  }

}

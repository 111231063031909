<template lang="html">
    <div class="faq" itemscope itemtype="https://schema.org/FAQPage">
        <h2>FAQ</h2>

        <div class="itemfaq" v-for="(item, key) in items" :key="item.index" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
            <div class="item" @click="toggleExpansion(key)">
                <p :id="'name-'+key" itemprop="name">{{item.title}}</p>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="">
                    <g class="arrows" :id="'arrows-'+key">
                        <path d="M0 0h24v24H0z" fill="none"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
                    </g>
                    </svg>
                </div>
            </div>

            <div class="description" :id="'description-'+key" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <p v-html="item.description" itemprop="text"></p>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data (){
        return {
            items: [
                {
                    title: 'Hvad er Stemman Podcast?',
                    description: `
                        Hej! 👋<br>Vi er et uafhængit podcast univers. Vi vil gerne lave programmer, der giver tid til fordybelse og pirrer din nysgerrighed, vi vil gerne provokere, forkæle og debattere.<br><br>
                        Vi lægger mange hundrede af timer i hver podcast-serie, for at sikre den dybde, som vi mener de emner vi beskæftiger os med fortjener.<br><br>
                        Vores app er bygget på moderne web-teknologier, og så indsamler vi ikke oplysninger om dig igennem uetiske marketing-cookies!<br>🍪 ❤️
                    `,
                },

                {
                    title: 'Hvordan henter jeg App\'en?',
                    description: `
                        Du kigger på den. Vi bruger en ny form for app, som både kan tilgåes som en hjemmeside og en app.<br>
                        Du kan hente hjemmesiden ned som app til din mobil ved at installere den. Det tager kun to tastetryk og mindre end ti sekunder.<br>
                        Se en udførlig guide her: <a href="https://app.stemman.dk/install" target="_blank">https://app.stemman.dk/install</a><br><br>
                        Har du en  <span class="bold">Andriod-telefon</span> kan du installerer appen ved at klikke på  <span class="bold">de tre prikker øverst til højre</span> og vælge <span class="bold">'Installér'</span><br><br>
                        Har du en Iphone kan du installerer appen ved at klikke på <span class="material-icons-round">ios_share</span> og vælge  <span class="bold">Føj til hjemmeskærm</span> i menuen.<br><br>
                    `,
                },


            ],
        }
    },
    methods: {
        toggleExpansion(key) {
            this.$el.querySelector('#arrows-'+key).classList.toggle('rotate');
            const description = this.$el.querySelector('#description-'+key);
            description.classList.toggle('expand');
            if(description.classList.contains('expand')){
                const name = new String(this.$el.querySelector('#name-'+key).innerHTML);
                window._paq.push(
                    ['trackContentInteraction', 'Expanded answer', 'FAQ', name],
                );
            }
        }
    }
}
</script>

<style lang="css" scoped>
svg{
  fill: var(--primary);
}
.item{
    border-top: 2px solid rgba(0,0,0,0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.faq:last-child{
    border-bottom: 2px solid rgba(0,0,0,0.2);
}
.description{
    text-align: left;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
}
.faq{
  text-align: center;
    margin: auto;
    padding: 10px;
}
.arrows{
    transform-origin: center;
    transition: all 0.4s ease;
}
.rotate{
    transform: scaleY(-1);
}
.expand{
    max-height: 500px;
    transition: max-height 0.3s ease-in;
}
</style>

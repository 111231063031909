<template>
  <div>
    <div :class="'svgdata svgdata'+this._uid" v-html="svgConstuct"></div>
  </div>
</template>

<script>
window.addEventListener('DOMContentLoaded', () =>{

});
import icons from '@/assets/icons/svg.js';


export default {
  name: 'pageSVG',
  props: {
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    svgData: {
      type: String,
      default: icons.notfound,
    },
    alt: {
      type: String,
      default: "",
    },
    fill: {
      type: String,
      default: 'none',
    },
    stroke: {
      type: String,
      default: 'var(--text)'
    }
  },
  computed: {
    svgConstuct: function(){
      return `<svg width="${this.width}" height="${this.height}" ${this.svgData}`;
    },
  },
  created: function(){
  let style = (function() {
      let style = document.createElement("style");
      // WebKit hack
      style.appendChild(document.createTextNode(""));
      document.head.appendChild(style);
      return style;
  })();
  style.sheet.insertRule(`.svgdata${this._uid} {fill:${this.fill}; stroke:${this.stroke};}`, 0);
},


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* .svgdata{
    THIS IS DYNAMICALLY INSERTED ON CREATED
} */

.svgdata >>> svg {
  display: block;
  margin: auto;
}

</style>
